import {
  Badge,
  Button,
  Image,
  IndexTable,
  LegacyCard,
  Text,
} from "@shopify/polaris";
import React from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { TableSkeleton } from "../../../components/skeletonLoader";
import StoreCustomBadge from "../../../components/customBadge/StoreCustomBadge";

const StoreTable = (props) => {
  const navigate = useNavigate();
  const resourceName = {
    singular: "store",
    plural: "stores",
  };
  function formatDateTime(datetime) {
    try {
      const date = moment(datetime);
      const formattedDate = date.format("MMM DD, YYYY, h:mm A");
      return formattedDate;
    } catch (err) {
      return "";
    }
  }

  const rowMarkup = props?.orders?.map(
    (
      {
        id,
        chargeStatus,
        name,
        country,
        createdAt,
        owner,
        paypalActive,
        plan,
        storeType,
        totalSync,
        totalOrders,
        countryCode,
        state,
        chargeFirstAcceptedAt,
      },
      index
    ) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>
          <Text>
            <div style={{ padding: "10px" }}> {id}</div>
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {countryCode === null ? (
              ""
            ) : (
              <Image
                width={15}
                source={`https://flagcdn.com/48x36/${countryCode.toLowerCase()}.png`}
                style={{ marginRight: "5px" }}
              />
            )}
            {name}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {totalSync}/{totalOrders}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {plan === null ? (
            ""
          ) : plan === "Basic" ? (
            <StoreCustomBadge textcolor="#000" text={plan} color="#a1edd0" />
          ) : plan === "Plus" ? (
            <StoreCustomBadge textcolor="#fff" text={plan} color="#F73434" />
          ) : (
            <StoreCustomBadge textcolor="#fff" text={plan} color="#181ff7" />
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div
            className="integration"
            style={{
              background:
                storeType === "SHOPIFY"
                  ? "#166AEA"
                  : storeType === "WOOCOMMERCE"
                  ? "#674399"
                  : storeType === "PRESTASHOP"
                  ? "#FF0076"
                  : storeType === "BIGCOMMERCE"
                  ? "#0D52FF"
                  : storeType === "WEEBLY"
                  ? "#3374FF"
                  : storeType === "WEBFLOW"
                  ? "#4958F7"
                  : storeType === "SALESFORCE"
                  ? "#00A1E0"
                  : storeType === "ECWID"
                  ? "#000000"
                  : storeType === "ADOBE COMMERCE"
                  ? "#FA0F00"
                  : storeType === "VOLUSION"
                  ? "#654BFB"
                  : storeType === "VOLUSION"
                  ? "#222222"
                  : "#166AEA",
            }}
          >
            {storeType}
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {paypalActive !== null && paypalActive ? (
            <Badge status="success">Active</Badge>
          ) : (
            <Badge status="critical">Not Active</Badge>
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>
          { state ? (
            <Badge status="success">Active</Badge>
          ) : (
            <Badge status="critical">Not Active</Badge>
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {chargeFirstAcceptedAt ? formatDateTime(chargeFirstAcceptedAt) : ""}
        </IndexTable.Cell>{" "}
        <IndexTable.Cell>
          <Button
            onClick={() => {
              sessionStorage.setItem("id", id);
              sessionStorage.removeItem("selectedTabIndex");
              navigate(`/viewStore/${id}/${storeType.toLowerCase()}`);
            }}
            primary
          >
            View
          </Button>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const ID = <div className="table_heading">ID</div>;
  const name = <div className="table_heading">Name</div>;
  const Synced_Total = <div className="table_heading">Synced / Total</div>;
  const Plan = <div className="table_heading">Plan</div>;
  const Integration = <div className="table_heading">Integration</div>;
  const PayPal_Status = <div className="table_heading">PayPal Status</div>;
  const status = <div className="table_heading">Charge Status</div>;
  const Occurred_at = <div className="table_heading">Occurred at</div>;

  return (
    <div style={{ marginTop: "30px" }}>
      <LegacyCard>
        {props.loading ? (
          <TableSkeleton />
        ) : (
          <IndexTable
            resourceName={resourceName || 0}
            itemCount={props?.orders?.length}
            headings={[
              { id: "ID", title: ID },
              { id: "name", title: name },
              { id: "Synced_Total", title: Synced_Total },
              { id: "Plan", title: Plan },
              { id: "Integration", title: Integration },
              { id: "PayPal_Status", title: PayPal_Status },
              { id: "status", title: status },
              { id: "Occurred_at", title: Occurred_at },
            ]}
            selectable={false}
          >
            {rowMarkup}
          </IndexTable>
        )}
      </LegacyCard>
    </div>
  );
};

export default StoreTable;
