import {
  Button,
  Checkbox,
  Icon,
  InlineError,
  LegacyCard,
  Page,
  Select,
  TextField,
  Tooltip,
} from "@shopify/polaris";
import { React, useCallback, useEffect, useState } from "react";
import ToggleButton from "../../../components/toggleButton/ToggleButton";
import { CircleInformationMajor } from "@shopify/polaris-icons";
import CourierMappingTable from "../CourierMapping/CourierMappingTable";
import { CarrierAddedPopup } from "../../../components/popup";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCarrierTable,
  getListCarrierByGeo,
  requestToAddCarriers,
} from "../../../redux/store/courierMapping/Action";
import { allCountriesApi } from "../../../redux/allCountries/Action";
import { getStoreDetails } from "../../../redux/store/find_by_id/Action";
import { useParams } from "react-router-dom";
import { courierSyncStatus } from "../../../redux/store/courierMappingToggle/Action";
import { useTranslation } from "react-i18next";
import SideMenu from "./SideMenu";

function CourierMapping() {
  const { id, storeType} = useParams();
  const { i18n, t } = useTranslation();
  const [selected, setSelected] = useState("");
  const [visible, setVisible] = useState(true);
  const [value, setValue] = useState("");
  const [referenceNote, setReferenceNote] = useState("");
  const [checked, setChecked] = useState(false);
  const [paypalCarrier, setPaypalCarrier] = useState("");
  const [paypalEnumerated, setPaypalEnumerated] = useState([]);
  const [addedAlert, setAddedAlert] = useState(false);
  const [originalCarrierError, setoriginalCarrierError] = useState(false);
  const [referenceNoteError, setreferenceNoteError] = useState(false);
  const [selectedError, setSelectedError] = useState(0);
  const [paypalCarrierError, setpaypalCarrierError] = useState(false);
  const [message, setMessage] = useState("");
  const [carrier, setCarriers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isPremiumUpgradeLoading = false;
  const activePLan = "Basic";
  const { addedCarrier, allCarriers, deleteCarrier, geoCarrier } = useSelector(
    (store) => store.requiredCourierMappingReducer
  );
  const { digital_sync } = useSelector(
    (store) => store.requiredPaypalSettingReducer
  );
  const allCountries = useSelector((store) => store.requiredAllCountriesData);
  const StoreDetailsData = useSelector(
    (store) => store.requireStoreDetailsRedycer
  );

  useEffect(() => {
    dispatch(getStoreDetails(id, storeType));
    dispatch(allCountriesApi(storeType));
    dispatch(getAllCarrierTable(id,storeType));
  }, []);

  const handleToggleAction = (state) => {
    const payload = {
      shopId: id,
      state: state,
      storeType:storeType
    };
    dispatch(courierSyncStatus(payload));
  };

  useEffect(() => {
    if (allCountries.isSuccess === true && allCountries.data !== undefined) {
      setCountryList(allCountries?.data);
    }
    if (allCountries?.data?.message === "Actual error message") {
      navigate("/logout");
    }
  }, [allCountries]);

  useEffect(() => {
    if (
      StoreDetailsData.isSuccess === true &&
      StoreDetailsData?.findById !== undefined &&
      StoreDetailsData?.findById?.courrierMapping !== undefined
    ) {
      setVisible(StoreDetailsData?.findById?.courrierMapping);
      setLoading(false);
    }
    if (StoreDetailsData?.findById?.message === "Actual error message") {
      navigate("/logout");
    }
  }, [StoreDetailsData]);

  const handleApplyRule = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (allCarriers !== undefined) {
      setCarriers(allCarriers);
    }

    if (geoCarrier.length !== 0) {
      setPaypalEnumerated(geoCarrier);
    }

    if (digital_sync.isRequest === true) {
      setLoading(true);
    }

    if (
      addedCarrier?.data?.message === "Actual error message" ||
      allCarriers?.message === "Actual error message" ||
      deleteCarrier?.data?.message === "Actual error message" ||
      geoCarrier?.message === "Actual error message" ||
      digital_sync?.data?.message === "Actual error message"
    ) {
      navigate("/logout");
    }
  }, [addedCarrier, allCarriers, deleteCarrier, geoCarrier, digital_sync]);

  useEffect(() => {
    if (deleteCarrier.isSuccess === true && deleteCarrier.data === true) {
      dispatch(getAllCarrierTable(id,storeType));
    }
    if (addedCarrier.isSuccess === true && addedCarrier.data !== undefined) {
      dispatch(getAllCarrierTable(id,storeType));
    }
  }, [deleteCarrier, addedCarrier]);

  const handleChange = useCallback((newValue) => setValue(newValue), []);
  const handleReferenceNote = useCallback((note) => setReferenceNote(note), []);
  const handlePaypalCarrier = (value) => {
    setPaypalCarrier(value);
  };

  const handleSelectChange = (value) => {
    const payload = {
      carrier: value,
      shopId: id,
      storeType:storeType
    };
    dispatch(getListCarrierByGeo(payload));
    setSelected(value);
  };
  const handleUpdate = () => { };
  const addPaypalCarrier = () => {
    if (value === "") {
      setoriginalCarrierError(true);
      setSelectedError(false);
      setreferenceNoteError(false);
      setpaypalCarrierError(false);
      setMessage("Please add Original Carrier");
    } else if (selected === "") {
      setSelectedError(true);
      setoriginalCarrierError(false);
      setreferenceNoteError(false);
      setpaypalCarrierError(false);
      setMessage("Please select destination country");
    } else if (paypalCarrier === "") {
      setpaypalCarrierError(true);
      setoriginalCarrierError(false);
      setSelectedError(false);
      setreferenceNoteError(false);
      setMessage("Please Select paypal carrier");
    } else if (referenceNote === "" || referenceNote === "Type reference") {
      setreferenceNoteError(true);
      setoriginalCarrierError(false);
      setSelectedError(false);
      setpaypalCarrierError(false);
      setMessage("please add Reference note!");
    } else {
      const payload = {
        id: 0,
        carrier: paypalCarrier,
        carrierToReplaceId: value,
        reason: referenceNote,
        createdAt: "2023-05-01T13:57:41.202Z",
        country: "string",
        applyRule: checked,
        shopId: id,
        storeType:storeType,
      };
      setoriginalCarrierError(false);
      setSelectedError(false);
      setreferenceNoteError(false);
      setpaypalCarrierError(false);
      setReferenceNote("");
      setValue("");
      setChecked(false);
      dispatch(requestToAddCarriers(payload));
      setAddedAlert(true);
      setPaypalCarrier("");
      setSelected("");
      handleUpdate();
    }
  };

  const digitalSync = (toggle) => { };

  const paypalCarrierOption = [];
  paypalEnumerated.forEach((i) => {
    if (i != null) {
      const carrierData = {
        label: i.paypalDescription,
        value: i.paypalEnumerated,
      };
      paypalCarrierOption.push(carrierData);
    }
  });

  const countryListOption = [];
  if (countryList?.length > 0) {
    countryList.forEach((i) => {
      if (i != null) {
        const countryData = {
          label: i.paypalGeoDescription,
          value: i.paypalGeo,
        };
        countryListOption.push(countryData);
      }
    });
  }

  return (
    <Page fullWidth>
      {addedAlert ? (
        <CarrierAddedPopup
          setDeletedAlert={setAddedAlert}
          deletedAlert={addedAlert}
        />
      ) : null}
      <div className="maindiv " style={{ display: "flex", gap: "20px" }}>
        <SideMenu id={id} storeType={storeType} activeTab="courier-mapping" />
        <div className="abc ">
          <LegacyCard sectioned>
            <div
              className="d-flex pr-20 pl-20"
              style={{
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <span className="courierHeading"> {t("Courier_mapping")} </span>
              <p className="couriertext">{t("Courier_Mapping_Allows")}</p>
              <span className="subHeading"> {t("By_Courier_Name")}</span>
              <p className="couriertext">{t("Courier_Mapping_Allows")}</p>
              {activePLan !== "Basic" ? (
                <div style={{ marginTop: "10px" }}>
                  <Button
                    loading={isPremiumUpgradeLoading}
                    onClick={() => { }}
                    plain
                  >
                    {t("Upgrade_to_Premium")}
                  </Button>
                </div>
              ) : (
                <ToggleButton
                  digitalSync={digitalSync}
                  visible={visible}
                  setVisible={setVisible}
                  loading={loading}
                  buttonOne={t("Yes")}
                  buttonTwo={t("No")}
                  handleToggleAction={handleToggleAction}
                />
              )}
            </div>
          </LegacyCard>
          {visible && activePLan === "Basic" ? (
            <LegacyCard sectioned>
              <div className="courierFilters mobileviewgap">
                <div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      background: "",
                      gap: "10px",
                    }}
                  >
                    <label htmlFor="Original carrier">
                      {" "}
                      {t("Original_carrier")}
                    </label>
                    <Tooltip content="Original Shopify Carrier (Case Sensitive)">
                      <Icon
                        source={CircleInformationMajor}
                        color="base"
                        width={10}
                      />
                    </Tooltip>
                  </div>
                  <TextField
                    label=""
                    value={value}
                    onChange={handleChange}
                    autoComplete="off"
                    placeholder={t("Type_carrier_name")}
                  />
                  {originalCarrierError ? (
                    <div style={{ position: "absolute" }}>
                      <InlineError message={message} fieldID="myFieldID" />
                    </div>
                  ) : null}
                </div>
                <div>
                  <Select
                    label={t("Destination_country")}
                    options={countryListOption}
                    onChange={handleSelectChange}
                    value={selected}
                    placeholder={t("Select_Destination_country")}
                  />
                  {selectedError ? (
                    <div style={{ position: "absolute" }}>
                      <InlineError message={message} fieldID="myFieldID" />
                    </div>
                  ) : null}
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      background: "",
                      gap: "10px",
                    }}
                  >
                    <label htmlFor="">{t("PayPal_carrier")}</label>
                    <Tooltip content="Select carrier name for PayPal">
                      <Icon
                        source={CircleInformationMajor}
                        color="base"
                        width={10}
                      />
                    </Tooltip>
                  </div>
                  <Select
                    label=""
                    options={paypalCarrierOption}
                    onChange={handlePaypalCarrier}
                    value={paypalCarrier}
                    placeholder={t("Select_Carrier")}
                  />
                  {paypalCarrierError ? (
                    <div style={{ position: "absolute" }}>
                      <InlineError message={message} fieldID="myFieldID" />
                    </div>
                  ) : null}
                </div>{" "}
                <div>
                  <TextField
                    label={t("Reference_Note")}
                    value={referenceNote}
                    onChange={handleReferenceNote}
                    autoComplete="off"
                    placeholder={t("Type_reference")}
                  />
                  {referenceNoteError ? (
                    <div style={{ position: "absolute" }}>
                      {" "}
                      <InlineError message={message} fieldID="myFieldID" />
                    </div>
                  ) : null}
                </div>
                <Checkbox
                  label={t("Apply_Rule")}
                  checked={checked}
                  onChange={handleApplyRule}
                />
                <span data-testid="add-paypal-carrier-id" onClick={() => {
                  addPaypalCarrier();
                }}
                >
                  <Button
                    primary
                  >
                    {t("Add_new_rule")}
                  </Button>
                </span>
              </div>
              <CourierMappingTable carrier={carrier} loading={loading} />
            </LegacyCard>
          ) : null}
        </div>
      </div>
    </Page>
  );
}

export default CourierMapping;
