import {
  FETCH_USAGE_DATA_REQUEST,
  FETCH_USAGE_DATA_SUCCESS,
  FETCH_USAGE_DATA_ERROR
} from "./ActionType";

const initialState = {
  loading: false,
  error: null,
  usageData: {
    currentPeriod: {
      startDate: '',
      endDate: '',
      currentDay: 0,
      ordersSynced: 0,
      forecast: 0,
      forecastOrdersSynced: 0,
      currentTier: '',
      price: 0
    },
    previousPeriods: []
  }
};

const usageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USAGE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_USAGE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        usageData: action.payload
      };
    case FETCH_USAGE_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default usageReducer;
