import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Divider,
  Image,
  LegacyCard,
  Page,
  Banner,
} from "@shopify/polaris";
import { Paypal } from "../../../assets";
import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import { getLinkedPaypalShop } from "../../../redux/store/dashboard/Action";
import { getStoreDetails } from "../../../redux/store/find_by_id/Action";
import { useSelector, useDispatch } from "react-redux";
import {
  requestToSetDeafultAccount,
  requestToDeleteAccount,
} from "../../../redux/store/paypalSettings/Action";
import { useTranslation } from "react-i18next";
import SideMenu from "./SideMenu";

const PaypalSettings = () => {
  const { id, storeType } = useParams();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [userAccount, setUserAccount] = useState([]);
  const [count, setCount] = useState(0);
  const [activePlan, setActivePlan] = useState("");
  const [message, setMessage] = useState("");
  const [infoBanner, setInfoBanner] = useState(false);
  const dispatch = useDispatch();
  const { linked_paypal } = useSelector(
    (store) => store.requiredStoreDashboardData
  );
  const details = useSelector((store) => store.requiredPaypalSettingReducer);
  const getPlanDetails = useSelector((store) => store.requireStoreDetailsRedycer);
  useEffect(() => {
    dispatch(getLinkedPaypalShop(id,storeType));
    dispatch(getStoreDetails(id, storeType));
  }, [dispatch, id, storeType]);

  const handleSetDefault = (id) => {
    dispatch(requestToSetDeafultAccount(id));
  };

  const handleDeleteAccount = (id) => {
    dispatch(requestToDeleteAccount(id));
  };

  useEffect(() => {
    if (linked_paypal !== undefined && linked_paypal.length !== 0) {
      const activeObjects = linked_paypal?.filter((obj) => obj.active);
      setCount(activeObjects.length);
      setUserAccount(linked_paypal);
    }
    if (
      getPlanDetails.isSuccess && getPlanDetails.findById.planName !== undefined) {
      setActivePlan(getPlanDetails.findById.planName);
    }
    if (linked_paypal?.message === "Actual error message") {
      navigate('/logout');
    }
    if (details.delete.isSuccess) {
      setInfoBanner(true);
      setMessage("You account is successfully Disconnected");
    }
    if (details.default.isSuccess) {
      setInfoBanner(true);
      setMessage("You account is successfully set to Default");
    }
  }, [linked_paypal, getPlanDetails,details.default.isSuccess, details.delete.isSuccess,navigate]);

  return (
    <>
      <Page fullWidth>
        {message && infoBanner ? (
          <div className="mb-1">
            <Banner title={message} status="info" onDismiss={() => { setInfoBanner(false) }}></Banner>
          </div>
        ) : (
          ""
        )}
        <div className="maindiv gap-20 d-flex">
          <SideMenu id={id} storeType={storeType} activeTab="paypal-settings" />
          <div className="abc ">
            <LegacyCard sectioned>
              <div
                className="d-flex pr-20 pl-20"
                style={{
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <span className="courierHeading"> Paypal</span>
                <p className="couriertext"></p>
                <div style={{ marginBottom: "20px" }}>
                  <Divider borderStyle="base" />
                </div>
                {userAccount?.map((i) => {
                  return (
                    <>
                      {i.active ? <div
                        className="d-flex"
                        style={{
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        <div className="d-flex" style={{ gap: "50px" }}>
                          <div className="d-flex" style={{ gap: "12px" }}>
                            <Image src={Paypal} width={30} />
                            <div>
                              <div className="d-flex" style={{ gap: "10px" }}>
                                <span className="userName">{i.payerEmail}</span>
                                {i.default ? (
                                  <span>
                                    <Badge status="success">
                                      <span className="default">
                                        {t("Default")}
                                      </span>
                                    </Badge>
                                  </span>
                                ) : null}
                              </div>
                              <span className="userID">{i.payerId}</span>
                            </div>
                          </div>
                          {i.orderCount ? (
                            <div className="orderContainer">{i.orderCount} {t("orders")}</div>
                          ) : (
                            ""
                          )}{" "}
                        </div>
                        <div>
                          <div className="d-flex" style={{ gap: "20px" }}>
                            <Button
                              disabled={i.default}
                              primary={!i.default}
                              onClick={() => {
                                handleSetDefault(i.id);
                              }}
                              loading={details.default.isRequest}
                            >
                              {t("Default")}
                            </Button>
                            <Button
                              outline
                              onClick={() => {
                                handleDeleteAccount(i.id);
                              }}
                              loading={details.delete.isRequest}
                            >
                              {t("Disconnect")}
                            </Button>
                          </div>
                        </div>
                      </div> : <div
                        className="d-flex"
                        style={{
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        <div className="d-flex" style={{ gap: "50px" }}>
                          <div className="d-flex" style={{ gap: "12px" }}>
                            <Image src={Paypal} width={30} />
                            <div>
                              <div className="d-flex" style={{ gap: "10px" }}>
                                <span className="userName">
                                  {i.payerEmail}
                                </span>
                                {i.default ? (
                                  <span>
                                    <Badge status="info">
                                      <span className="default">
                                        {t("Detected")}
                                      </span>
                                    </Badge>
                                  </span>
                                ) : null}
                              </div>
                              <span className="userID">{i.payerId}</span>
                            </div>
                          </div>
                          {i.orderCount > 0 && (
                            <div>
                              <Button destructive>
                                {i.orderCount} {t("orders")}{" "}
                              </Button>
                            </div>
                          )}
                        </div>
                        {count >= 1 && activePlan === "Basic" ? (
                          <Button disabled>
                            <span>{t("Upgrade_to_connect_and_Sync")}</span>
                          </Button>
                        ) : activePlan === "Premium" && i.active === false ? (
                          <div className="cursor_pointer">
                            <Button primary disabled>
                              Add paypal account
                            </Button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      }
                      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                        <Divider borderStyle="base" />
                      </div>
                    </>
                  );
                })}

                <div style={{ display: "flex", justifyContent: "end" }}>
                  {/* {!isPaymentLoading ? (
                    <button
                      onClick={() => {}}
                      className="addPaypalBtnContainer"
                    >
                      <Image src={Group} width={20} />
                      <span> Add another Paypal account </span>
                    </button>
                  ) : (
                    <Button loading>
                      <span> Add another Paypal account </span>
                    </Button>
                  )} */}
                </div>
              </div>
            </LegacyCard>
          </div>
        </div>
      </Page>
    </>
  );
};

export default PaypalSettings;
