import { ROUTES_NAME } from "../../../config/constants";
import { ACCESS_TOKEN } from "../../../constants";
import {
  FETCH_USAGE_DATA_REQUEST,
  FETCH_USAGE_DATA_SUCCESS,
  FETCH_USAGE_DATA_ERROR
} from "./ActionType";

export const fetchUsageData = (shopId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_USAGE_DATA_REQUEST });

    try {
      const usageUrl = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.usage(shopId)}`;
      const response = await fetch(usageUrl, {
        method: "POST",
        credentials: "include",
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
          "Content-Type": "application/json",
          Origin: `${process.env.REACT_APP_URL}`,
        },
      });
      
      const data = await response.json();
      dispatch({
        type: FETCH_USAGE_DATA_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: FETCH_USAGE_DATA_ERROR,
        payload: error.message
      });
    }
  };
};
