import React from "react";
import { LegacyCard } from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SideMenu = ({ id, storeType, activeTab }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="firstdiv">
      <LegacyCard sectioned>
        <ul className="navItems">
          <li
            data-testid="subscription-side-nav-id"
            className={`navItem ${activeTab === "subscription" ? "activeTab" : ""}`}
            onClick={() => {
              navigate(`/viewStore/${id}/${storeType}/subscription`);
            }}
          >
            {t("Subscription")}
          </li>
          <li
            data-testid="usage-side-nav-id"
            className={`navItem ${activeTab === "usage" ? "activeTab" : ""}`}
            onClick={() => {
              navigate(`/viewStore/${id}/${storeType}/usage`);
            }}
          >
            {t("Usage")}
          </li>
          <li
            data-testid="digital-product-side-nav-id"
            className={`navItem ${activeTab === "digital-product" ? "activeTab" : ""}`}
            onClick={() => {
              navigate(`/viewStore/${id}/${storeType}/digital-product`);
            }}
          >
            {t("Digital_product")}{" "}
          </li>
          <li
            data-testid="courier-mapping-side-nav-id"
            className={`navItem ${activeTab === "courier-mapping" ? "activeTab" : ""}`}
            onClick={() => {
              navigate(`/viewStore/${id}/${storeType}/courier-mapping`);
            }}
          >
            {t("Courier_mapping")}
          </li>
          <li
            data-testid="paypal-setting-side-nav-id"
            className={`navItem ${activeTab === "paypal-settings" ? "activeTab" : ""}`}
            onClick={() => {
              navigate(`/viewStore/${id}/${storeType}/paypalsetting`);
            }}
          >
            {t("PayPal_settings")}
          </li>
        </ul>
      </LegacyCard>
    </div>
  );
};

export default SideMenu;
