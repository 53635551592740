import { React, useEffect } from "react";
import { LegacyCard, Page, Spinner, Banner, VerticalStack, Card, Text } from "@shopify/polaris";
import { CurrentUsageTable } from "./usage/CurrentUsageTable";
import { ForecastTable } from "./usage/ForecastTable";
import { PreviousUsageTable } from "./usage/PreviousUsageTable";
import { formatDate } from "../../../redux/store/usage/usage";
import { useParams } from "react-router-dom";
import SideMenu from "./SideMenu";
import { useSelector, useDispatch } from "react-redux";
import { fetchUsageData } from "../../../redux/store/usage/Action";
import { useTranslation } from "react-i18next";

const Usage = (props) => {
  const { id, storeType } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { usageData, loading, error } = useSelector((state) => state.usage);

  useEffect(() => {
    dispatch(fetchUsageData(id));
  }, [dispatch, id]);

  return (
    <Page fullWidth>
      <div className="maindiv " style={{ display: "flex", gap: "20px" }}>
        <SideMenu id={id} storeType={storeType} activeTab="usage" />

        <div className="abc">
          <LegacyCard sectioned>
            {error ? (
              <Banner title={t("Error loading usage data")} status="critical">
                <p>{error.message || t("An error occurred while loading the usage data. Please try again later.")}</p>
              </Banner>
            ) : loading ? (
              <div style={{ display: "flex", justifyContent: "center", padding: "2rem" }}>
                <Spinner accessibilityLabel={t("Loading")} size="large" />
              </div>
            ) : usageData?.currentPeriod ? (
              <VerticalStack gap="4">
                {/* Current Usage Section */}
                <Card>
                  <VerticalStack gap="4">
                    <Text as="h2" variant="headingMd">
                      {t("Current usage")}
                    </Text>
                    <CurrentUsageTable
                      startDate={formatDate(usageData.currentPeriod.startDate)}
                      endDate={formatDate(usageData.currentPeriod.endDate)}
                      ordersSynced={usageData.currentPeriod.ordersSynced}
                      currentDay={usageData.currentPeriod.currentDay}
                    />
                  </VerticalStack>
                </Card>

                {/* Forecast Section */}
                <Card>
                  <VerticalStack gap="4">
                    <Text as="h2" variant="headingMd">
                      {t("Forecast")}
                    </Text>
                    <ForecastTable
                      startDate={formatDate(usageData.currentPeriod.startDate)}
                      endDate={formatDate(usageData.currentPeriod.endDate)}
                      forecast={usageData.currentPeriod.forecastOrdersSynced}
                      amount={usageData.currentPeriod.forecast}
                      tier={usageData.currentPeriod.currentTier}
                    />
                  </VerticalStack>
                </Card>

                {/* Previous Usage Section */}
                <Card>
                  <VerticalStack gap="4">
                    <Text as="h2" variant="headingMd">
                      {t("Previous usage")}
                    </Text>
                    <PreviousUsageTable rows={usageData.previousPeriods || []} />
                  </VerticalStack>
                </Card>
              </VerticalStack>
            ) : (
              <div style={{ textAlign: "center", padding: "2rem" }}>
                <h2>
                  <strong>{t("Standard Billing Plan")}</strong>
                </h2>
                <p style={{ marginTop: "1rem" }}>
                  {t("This store is not on usage-based pricing. It follows a standard billing plan.")}
                </p>
              </div>
            )}
          </LegacyCard>
        </div>
      </div>
    </Page>
  );
};

export default Usage;
