import { combineReducers } from "redux";
import dashboardCardReducer from "./dashboard/ActionReducer";
import paypalSettingReducer from "./troubleshooting/paypalSetting/ActionReducer";
import usageReducer from "./store/usage/ActionReducer";
import pullLogReducer from "./troubleshooting/pullLogs/ActionReducer";
import reportReducer from "./reportReducer/ActionReducer";
import carriersReducer from "./carriers/ActionReducer";
import eventsReducer from "./troubleshooting/events/ActionReducer";
import findByIDReducer from "./troubleshooting/clientDetails/ActionReducer";
import statsReducer from "./troubleshooting/stats/ActionReducer";
import processesReducer from "./troubleshooting/processes/ActionReducer";
import processesQueueReducer from "./troubleshooting/processes/processesQueue/ActionReducer";
import storeDashboardReducer from "./store/dashboard/ActionReducer";
import historicalOrdersReducer from "./store/historicalOrders/ActionReducer";
import allCountriesReducer from "./allCountries/ActionReducer";
import shopCountriesReducer from "./shopCountries/ActionReducer";
import advanceSearchStoreReducer from "./orders/ActionReducer";
import storeOrdersReducer from "./store/orders/ActionReducer";
import storeCourierMappingReducer from "./store/courierMapping/ActionReducer";
import storePaypalSettingsReducer from "./store/paypalSettings/ActionReducer";
import findByIdReducer from "./store/find_by_id/ActionReducer";
import fullfillmentReducer from "./store/fullfillment/ActionReducer";
import paypalCheckerReducer from "./store/paypalChecker/ActionReducer";
import fetchByNameReducer from "./troubleshooting/fetch-by-name/ActionReducer";
import reindexReducer from "./troubleshooting/reindex/ActionReducer";
import gdprDataReducer from "./gdpr/ActionReducer";
import cacheSyncReducer from "./troubleshooting/cache-sync/ActionReducer";
import orderGeneratorReducer from "./store/orderGenerator/ActionReducer";

 const rootReducer  = combineReducers({
    requriedDashboardCardData : dashboardCardReducer,
    requriedAdvanceSearchStoreTable : advanceSearchStoreReducer,
    requriedPaypalSettingData : paypalSettingReducer,
    requriedPullLogData : pullLogReducer,
    requriedReportData : reportReducer,
    requriedCarriersData : carriersReducer,
    requriedEventsData: eventsReducer,
    requriedFindDetails: findByIDReducer,
    requiredStatsData: statsReducer,
    requiredProcessesData: processesReducer,
    requiredQueueProcessesData: processesQueueReducer,
    requiredStoreDashboardData: storeDashboardReducer,
    requiredHistoricalData: historicalOrdersReducer,
    requiredAllCountriesData : allCountriesReducer,
    requiredShopCountriesData : shopCountriesReducer,
    requiredStoreOrdersReducer: storeOrdersReducer,
    requiredCourierMappingReducer: storeCourierMappingReducer,
    requiredPaypalSettingReducer : storePaypalSettingsReducer,
    requireStoreDetailsRedycer : findByIdReducer,
    requiredFullfillmentReducer : fullfillmentReducer,
    requiredPaypalCheckerReducer : paypalCheckerReducer,
    requireFetchByNameReducer : fetchByNameReducer,
    requiredReIndexReducer: reindexReducer,
    requiredGdprReducer: gdprDataReducer,
    requiredCacheSyncReducer: cacheSyncReducer,
    orderGenerator: orderGeneratorReducer,
    usage: usageReducer
})

export default rootReducer;
