import React from "react";
import { IndexTable } from "@shopify/polaris";

export function CurrentUsageTable({ startDate, endDate, ordersSynced, currentDay }) {
  const headings = [{ title: "Billing period" }, { title: "Orders synced" }, { title: "Days Remaining" }];

  const daysRemaining = 30 - currentDay;
  const period = `${startDate} - ${endDate}`;

  return (
    <IndexTable headings={headings} itemCount={1} selectable={false}>
      <IndexTable.Row id="1" position={0}>
        <IndexTable.Cell>{period}</IndexTable.Cell>
        <IndexTable.Cell>{ordersSynced}</IndexTable.Cell>
        <IndexTable.Cell>{daysRemaining}</IndexTable.Cell>
      </IndexTable.Row>
    </IndexTable>
  );
}
