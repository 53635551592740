
export const FulfillmentStatus = Object.freeze({
  FULFILLED: "FULFILLED",                               // includes a carrier and tracking number
  FULFILLED_NO_CARRIER: "FULFILLED_NO_CARRIER",         // Fulfilled without carrier
  FULFILLED_NO_TRACKING: "FULFILLED_NO_TRACKING",       // Fulfilled without tracking
  FULFILLED_ONLY_TRACKING_NUMBER: "FULFILLED_ONLY_TRACKING_NUMBER",       // Fulfilled tracking which contains only trackingNumber(no company and url)
  TWO_FULFILLMENTS: "TWO_FULFILLMENTS",                 // two fulfillments, both with carrier+tracking
  TWO_FULFILLMENTS_PARTIAL: "TWO_FULFILLMENTS_PARTIAL", // one with carrier+tracking, one without
  NONE: "NONE",                                          // no fulfillments
  MISSING_MERCHANT_ID: "MISSING_MERCHANT_ID"            // can't find merchantId from in data
});
