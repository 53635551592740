import { Button, IndexTable, LegacyCard, Badge, Image, EmptySearchResult } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAdvanceSearchStoreData } from "../../../redux/orders/Action";
import {
  SmallShopifyIcon,
  Bigcommerce,
  woocommerce,
  webflow,
  prestashop,
} from "../../../assets";
import moment from "moment";
import { TableSkeleton } from "../../../components/skeletonLoader";

const LatestStore = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();
  const advanceSearch = useSelector(
    (store) => store.requriedAdvanceSearchStoreTable
  );
  useEffect(() => {
    const payload = {
      page: 0,
      pageLength: 10,
      name: null,
      country: null,
      chargesStatus: null,
      startDate: null,
      endDate: null,
      storeType:null,
    };
    dispatch(getAdvanceSearchStoreData(payload));
  },[dispatch]);

  useEffect(() => {
    if (
      advanceSearch.isSuccess &&
      advanceSearch?.advanceSearch !== undefined && advanceSearch?.advanceSearch?.content !== undefined
    ) {
      setOrders(advanceSearch?.advanceSearch?.content);
    }
    if(advanceSearch?.advanceSearch?.message === "Actual error message") {
      navigate('/logout')
    }
  }, [advanceSearch, navigate]);

  const resourceName = {
    singular: "store",
    plural: "stores",
  };
  const emptyStateMarkup = (
    <EmptySearchResult
      title={'No  stores found'}
      description={''}
      withIllustration
    />
  );

  function formatDateTime(datetime) {
    try {
      const date = moment(datetime);
      const formattedDate = date.format("MMM DD, YYYY, h:mm A");
      return formattedDate;
    } catch (err) {
      return "";
    }
  }


  const rowMarkup = orders?.map(
    (
      {
        id,
        state,
        name,
        country,
        createdAt,
        countryCode,
        owner,
        paypalActive,
        plan,
        shopifyPlan,
        storeType,
        totalSync,
        totalOrders,
        chargeFirstAcceptedAt
      },
      index
    ) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>
          <div className="p-10">
            {countryCode === null ? (
              ""
            ) : (
              <Image
                width={15}
                source={`https://flagcdn.com/48x36/${countryCode.toLowerCase()}.png`}
                style={{ marginRight: "5px" }}
              />
            )}
            {name}
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {totalSync}/{totalOrders}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {plan !== null ? (
            <Badge progress="complete" status="success">
              {plan}
            </Badge>
          ) : (
            ""
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {plan !== null && storeType === "SHOPIFY" ? (
            <Badge status="success">
              <div className="d-flex gap-10">
                <Image source={SmallShopifyIcon} />
                <p>{shopifyPlan}</p>
              </div>
            </Badge>
          ) : plan !== null && storeType === "WIX" ? (
            <Badge status="success">
              <div className="d-flex gap-10">
                <Image source="https://www.wix.com/favicon.ico" width={10} />
                <p>{shopifyPlan}</p>
              </div>
            </Badge>
          ) : plan !== null && storeType === "ECWID" ? (
            <Badge status="success">
              <div className="d-flex gap-10">
                <Image source="https://www.ecwid.com/favicon.ico" width={10} />
                <p>{shopifyPlan}</p>
              </div>
            </Badge>
          ) : plan !== null && storeType === "BIGCOMMERCE" ? (
            <Badge status="success">
              <div className="d-flex gap-10">
                <Image source={Bigcommerce} width={10} />
                <p>{shopifyPlan}</p>
              </div>
            </Badge>
          ) : plan !== null && storeType === "WOOCOMMERCE" ? (
            <Badge status="success">
              <div className="d-flex gap-10">
                <Image source={woocommerce} width={10} />
                <p>{shopifyPlan}</p>
              </div>
            </Badge>
          ) : plan !== null && storeType === "WEBFLOW" ? (
            <Badge status="success">
              <div className="d-flex gap-10">
                <Image source={webflow} width={10} />
                <p>{shopifyPlan}</p>
              </div>
            </Badge>
          ) : plan !== null && storeType === "PRESTASHOP" ? (
            <Badge status="success">
              <div className="d-flex gap-10">
                <Image source={prestashop} width={10} />
                <p>{shopifyPlan}</p>
              </div>
            </Badge>
          ) : (
            ""
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {paypalActive !== null && paypalActive ?
          (<Badge status="success">Active</Badge>)
           : (<Badge status="critical">Not Active</Badge>)}
        </IndexTable.Cell>
        <IndexTable.Cell>
            { state
            ? (<Badge status="success">Active</Badge>)
             : (<Badge status="critical">Not Active</Badge>)}
        </IndexTable.Cell>
        <IndexTable.Cell>{chargeFirstAcceptedAt ? formatDateTime(chargeFirstAcceptedAt) : ""}</IndexTable.Cell>
        <IndexTable.Cell>
          <Button
            onClick={() => {
              sessionStorage.removeItem("selectedTabIndex");
              sessionStorage.setItem("id", id);
              console.log(storeType,":")
              navigate(`/viewStore/${id}/${storeType.toLowerCase()}`);
            }}
            primary
          >
            View
          </Button>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const name = <div className="table_heading">Name</div>;
  const Synced_Total = <div className="table_heading">Synced / Total</div>;
  const Plan = <div className="table_heading">Plan</div>;
  const IntegrationPlan = <div className="table_heading">Integration Plan</div>;
  const paypalStatus = <div className="table_heading">Paypal Status</div>;
  const status = <div className="table_heading">Charge Status</div>;
  const Occurredat = <div className="table_heading">Occurred at</div>;
  const Action = <div className="table_heading">Action</div>;

  return (
    <div style={{ marginTop: "30px", padding: "0 30px" }}>
      <LegacyCard sectioned>
        <LegacyCard sectioned>
          <div className="d-flex justify-content-between align-item-center flex-wrap gap-10"
            
          >
            <p className="title">Latest Store</p>
            <div className="pb-10">
            <Button
              primary
              onClick={() => {
                navigate("/store");
              }}
            >
              View all store
            </Button>
            </div>
            
          </div>
          {advanceSearch.isRequest ? (
            <TableSkeleton />
          ) : (
            <IndexTable
              resourceName={resourceName || 0 }
              itemCount={orders?.length}
              emptyState={emptyStateMarkup}
              headings={[
                { id: "name", title: name },
                { id: "Synced_Total", title: Synced_Total },
                { id: "Plan", title: Plan },
                { id: "IntegrationPlan", title: IntegrationPlan },
                { id: "paypalStatus", title: paypalStatus },
                { id: "status", title: status },
                { id: "Occurredat", title: Occurredat },
                { id: "Action", title: Action },
              ]}
              selectable={false}
            >
              {rowMarkup}
            </IndexTable>
          )}
        </LegacyCard>
      </LegacyCard>
    </div>
  );
};

export default LatestStore;
