import React from "react";
import { IndexTable } from "@shopify/polaris";
import { formatPeriod } from "../../../../redux/store/usage/usage";

export function PreviousUsageTable({ rows }) {
  const headings = [
    { title: "Billing period" },
    { title: "Orders synced" },
    { title: "Amount" },
    { title: "Tier" },
    { title: "Charge Id" },
  ];

  return (
    <IndexTable headings={headings} itemCount={rows.length} selectable={false}>
      {rows.map(({ startDate, endDate, ordersSynced, amount, tier, chargeId }, index) => (
        <IndexTable.Row id={index.toString()} position={index} key={index}>
          <IndexTable.Cell>{formatPeriod(startDate, endDate)}</IndexTable.Cell>
          <IndexTable.Cell>{ordersSynced}</IndexTable.Cell>
          <IndexTable.Cell>{amount}</IndexTable.Cell>
          <IndexTable.Cell>{tier}</IndexTable.Cell>
          <IndexTable.Cell>{chargeId}</IndexTable.Cell>
        </IndexTable.Row>
      ))}
    </IndexTable>
  );
}
