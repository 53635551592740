import moment from "moment/moment";
import capitalize from "lodash.capitalize";
import React from "react";
import { Icon, Tooltip } from "@shopify/polaris";
import {
  CircleCancelMinor,
  CircleTickOutlineMinor,
  InfoMinor,
} from "@shopify/polaris-icons";

export function OrderCheckList({ data, shop, checkedAt }) {
  const fulfilledAt = data.fulfilledAt
    ? moment(data.fulfilledAt).format("MMM DD, YYYY, h:mm A Z")
    : null;

  const checkedDate = moment(checkedAt).format("MMM DD, YYYY, h:mm A Z");
  const shopId = shop.replace(".myshopify.com", "");
  const paypalError = getPaypalError(data);

  return (
    <div className="orderChecker-checklist">
      <h3>
        <a
          className="Polaris-Link"
          href={`https://admin.shopify.com/store/${shopId}/orders/${data.orderId}`}
          target="_blank"
        >
          Order {data.orderNumber}: {data.orderId}
        </a>
      </h3>
      <table>
        <CheckListItem
          success={data.fulfilledInLast60Days}
          label="Fulfilled in last 60 days:"
          value={fulfilledAt ?? "(date missing)"}
        />

        <CheckListItem
          success={data.gateway === "paypal"}
          label="Paid via:"
          value={getGatewayValue(data)}
        />

        <CheckListItem
          success={data.trackingCode}
          label="Tracking code:"
          value={data.trackingCode ?? "(missing)"}
        />

        <CheckListItem
          success={data.carrier}
          label="Carrier:"
          value={data.carrier ?? "(missing)"}
        />

        <CheckListItem
          success={data.fulfilledStatus === "FULFILLED"}
          label="Fulfilled status:"
          value={formatStatus(data.fulfilledStatus)}
        />

        <CheckListItem
          success={!paypalError}
          label={paypalError ?? "PayPal account connected:"}
          value={getPaypalValue(data)}
        />
      </table>
      <div className="orderChecker-checkedAt">
        Checked at {checkedDate} for {shop}
      </div>
    </div>
  );
}

function getPaypalError(data) {
  if (!data.paypalAccountId) {
    return "PayPal account:";
  }

  if (!data.paypalAccountConnected) {
    return "PayPal account not connected:";
  }

  if (!data.paypalAccountActive) {
    return "PayPal account not active:";
  }

  if (!data.paypalTokenValid) {
    return "PayPal token expired:";
  }
}

function getPaypalValue(data) {
  return (
    <>
      {data.paypalAccountId ?? "(missing)"}{" "}
      {data.paypalTokenError && (
        <Tooltip
          content={
            <pre className="Polaris-Text--bodyMd">
              {JSON.stringify(data.paypalTokenError, null, 2)}
            </pre>
          }
          persistOnClick
          width="auto"
        >
          <div className="orderChecker-tooltip">
            <Icon source={InfoMinor} color="subdued" />
          </div>
        </Tooltip>
      )}
    </>
  );
}

function getGatewayValue(data) {
  return (
    <>
      {data.gatewayName}{" "}
      {data.gateway && (
        <Tooltip content={`ID: ${data.gateway}`}>
          <div className="orderChecker-tooltip">
            <Icon source={InfoMinor} color="subdued" />
          </div>
        </Tooltip>
      )}
    </>
  );
}

function formatStatus(status) {
  return status
    .split(/[^a-z]/gim)
    .map(capitalize)
    .join(" ");
}

function CheckListItem({ success, label, value }) {
  return (
    <tr>
      <td className="label">
        <div className="user_key">
          {success ? (
            <Icon source={CircleTickOutlineMinor} color="success" />
          ) : (
            <Icon source={CircleCancelMinor} color="warning" />
          )}{" "}
          <span>{label}</span>
        </div>
      </td>
      <td className="value">
        <span className="user_value">{value}</span>
      </td>
    </tr>
  );
}
