import React from "react";
import { IndexTable } from "@shopify/polaris";

export function ForecastTable({ startDate, endDate, forecast, amount, tier }) {
  const headings = [{ title: "Billing period" }, { title: "Estimated orders" }, { title: "Amount" }, { title: "Tier" }];

  const period = `${startDate} - ${endDate}`;

  return (
    <IndexTable headings={headings} itemCount={1} selectable={false}>
      <IndexTable.Row id="1" position={0}>
        <IndexTable.Cell>{period}</IndexTable.Cell>
        <IndexTable.Cell>{forecast}</IndexTable.Cell>
        <IndexTable.Cell>${amount}</IndexTable.Cell>
        <IndexTable.Cell>{tier}</IndexTable.Cell>
      </IndexTable.Row>
    </IndexTable>
  );
}
